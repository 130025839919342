<template>
  <div>
    <Panel header="Proposta" class="mt-3">
      <form @submit.prevent="validate()">
        <Toolbar v-if="tipo === 'NOVA_AVERBACAO'" class="mb-4">
          <template #start>
            <Button
              label="Voltar"
              icon="pi pi-chevron-left"
              class="mt-2 mr-2 p-button-sm p-button-secondary"
              @click="$router.back()" />
          </template>
        </Toolbar>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="rubrica" class="required">Rubrica</label>
            <Dropdown
              id="rubrica"
              v-model="v$.proposta.rubrica.$model"
              :class="{
                'p-invalid': submitted && v$.proposta.rubrica.$invalid,
              }"
              optionLabel="nome"
              :options="rubricas"
              placeholder="Selecione uma rubrica"
              @change="tratarCarencia()">
              <template #value="slotProps">
                <div v-if="slotProps.value.rubrica">
                  <span>
                    {{ slotProps.value.rubrica }} | {{ slotProps.value.nome }} |
                    {{ slotProps.value.atividade.nome }}
                  </span>
                </div>
                <div v-else>
                  <span>{{ slotProps.placeholder }}</span>
                </div>
              </template>

              <template #option="slotProps">
                <div>
                  <span>
                    {{ slotProps.option.rubrica }} |
                    {{ slotProps.option.nome }} |
                    {{ slotProps.option.atividade.nome }}
                  </span>
                </div>
              </template>
            </Dropdown>
            <div
              v-if="submitted && v$.proposta.rubrica.required.$invalid"
              class="p-error">
              O campo rubrica é obrigatório.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="contrato" class="required">Nº Contrato</label>
            <InputText
              id="contrato"
              v-model="v$.proposta.contrato.$model"
              :class="{
                'p-invalid': submitted && v$.proposta.contrato.$invalid,
              }"
              type="text"
              placeholder="Digite o número contrato" />
            <div
              v-if="submitted && v$.proposta.contrato.required.$invalid"
              class="p-error">
              O campo Nº Contrato é obrigatório.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="agencia">Agência</label>
            <InputText
              id="agencia"
              v-model="proposta.agencia"
              type="text"
              maxlength="4" />
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="valorParcela" class="required">Valor Parcela</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputNumber
                id="valorParcela"
                v-model="v$.proposta.valorParcela.$model"
                mode="decimal"
                locale="pt-BR"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                :class="{
                  'p-invalid': submitted && v$.proposta.valorParcela.$invalid,
                }"
                placeholder="0,00" />
            </div>
            <div
              v-if="submitted && v$.proposta.valorParcela.required.$invalid"
              class="p-error">
              O campo valor parcela é obrigatório.
            </div>
            <div
              v-if="
                submitted && valorParcelaMenorIgualTotalConsignacoesSelecionadas
              "
              class="p-error">
              O campo valor parcela não pode ser maior do que o total a ser
              comprado.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="prazoTotal" class="required">Prazo</label>
            <InputNumber
              id="prazoTotal"
              v-model="v$.proposta.prazoTotal.$model"
              :min="0"
              :max="999"
              :class="{
                'p-invalid': submitted && v$.proposta.prazoTotal.$invalid,
              }"
              @input="
                conferirPrazoEDataParcela(v$.proposta.prazoTotal.$model)
              " />
            <div
              v-if="submitted && v$.proposta.prazoTotal.required.$invalid"
              class="p-error">
              O campo prazo total é obrigatório.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="carencia" class="required">
              Data da Primeira Parcela:
            </label>
            <Dropdown
              id="carencia"
              v-model="v$.proposta.carencia.$model"
              :options="datasCarencia"
              optionLabel="smesCarencia"
              placeholder="Selecione uma carência"
              :class="{
                'p-invalid': submitted && v$.proposta.carencia.$invalid,
              }">
            </Dropdown>
            <div
              v-if="submitted && v$.proposta.carencia.required.$invalid"
              class="p-error">
              O campo carência é obrigatório
            </div>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="obs" class="required">Observação</label>
            <Textarea
              id="obs"
              v-model="v$.proposta.obs.$model"
              :autoResize="true" />
            <div
              v-if="submitted && v$.proposta.obs.required.$invalid"
              class="p-error">
              O campo observação é obrigatório
            </div>
          </div>
        </div>
        <Button
          type="submit"
          autofocus
          :disabled="saving"
          @click="adicionarAverbacao()">
          <span v-if="!saving" class="pi pi-dollar"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar Proposta</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </form>
    </Panel>

    <modal-senha-servidor
      v-if="tipo === 'COMPRA_DE_DIVIDA'"
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="salvar"
      :tipo="true"
      @mudarVisibilidade="
        exibirModalSenhaServidor = $event
      "></modal-senha-servidor>
    <ModalSenhaServidor
      v-if="tipo === 'RENEGOCIACAO'"
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="aprovarProposta"
      :metodoSalvar2="finalizarSemAprovarProposta"
      :tipo="true"
      :isNovaAverbacao="true"
      :showConsignacao="novaAverbacao"
      :labelConfirm1Button="'Renegociar'"
      :labelConfirm2Button="'Criar Proposta'"
      @mudarVisibilidade="
        exibirModalSenhaServidor = $event
      "></ModalSenhaServidor>
    <ModalSenhaServidor
      v-if="tipo === 'NOVA_AVERBACAO'"
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="aprovarProposta"
      :metodoSalvar2="finalizarSemAprovarProposta"
      :tipo="true"
      :isNovaAverbacao="true"
      :showConsignacao="novaAverbacao"
      :labelConfirm1Button="'Averbar'"
      :labelConfirm2Button="'Criar Proposta'"
      @mudarVisibilidade="
        exibirModalSenhaServidor = $event
      "></ModalSenhaServidor>

    <Dialog
      header="Número de parcelas e data da primeira parcela conferem?"
      :visible.sync="displayBasic"
      :containerStyle="{ width: '40vw' }">
      <p>
        Número de Parcelas: <b>{{ proposta.prazoTotal }}</b>
      </p>
      <p>
        Data da Primeira Parcela:
        <b>
          {{ proposta.carencia.mesReferencia }}/{{
            proposta.carencia.anoReferencia
          }}</b
        >
      </p>
      <template #footer>
        <Button
          label="Sim"
          icon="pi pi-check"
          class="p-button-text"
          @click="fecharModarlConferirPrazo" />
        <Button
          label="Não"
          icon="pi pi-times"
          autofocus
          @click="naoConferemPrazo" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Proposta from '@/domain/Proposta.js'
import RubricaService from '@/service/RubricaService.js'
import PropostaService from '@/service/PropostaService'
import UtilService from '@/service/UtilService'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'

export default {
  components: {
    ModalSenhaServidor,
  },

  props: {
    tipo: {
      type: String,
      default: null,
    },
    metodoSalvar: {
      type: Function,
      default: null,
    },
    metodoSalvar2: {
      type: Function,
      default: null,
    },
    usaSenha: {
      type: Boolean,
      default: null,
    },
    isSalvarOk: {
      type: Boolean,
      default: false,
    },
    totalSomaValoresConsignacoesSelecionadas: {
      type: Number,
      default: 0,
    },
    labelConfirmButton: {
      type: String,
      default: 'Confirmar',
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      displayBasic: false,
      proposta: new Proposta(),
      exibirModalSenhaServidor: false,
      submitted: false,
      matricula: {
        id: this.$route.params.id,
      },
      rubricas: [
        {
          nome: '',
        },
      ],
      dataAtual: {},
      saving: false,
      valorParcelaMenorIgualTotalConsignacoesSelecionadas: false,
      novaAverbacao: {},
      prazoCarencia: 12,
      datasCarencia: [
        {
          smesCarencia: '',
          mesReferencia: 0,
          anoReferencia: 0,
        },
      ],
    }
  },

  computed: {
    nomeBotao() {
      return this.tipo
    },
  },

  created() {
    this.rubricaService = new RubricaService(this.$http)
    this.propostaService = new PropostaService(this.$http)
    this.utilService = new UtilService(this.$http)
  },

  mounted() {
    this.carregarRubricas()
  },

  validations() {
    return {
      proposta: {
        rubrica: {
          required,
        },
        contrato: {
          required,
        },
        valorParcela: {
          required,
        },
        prazoTotal: {
          required,
        },
        carencia: {
          required,
        },
        obs: {
          required,
        },
      },
    }
  },

  methods: {
    fecharModarlConferirPrazo() {
      this.displayBasic = false
    },
    naoConferemPrazo() {
      this.displayBasic = false
      this.proposta.prazoTotal = null
    },
    conferirPrazoEDataParcela(quantidade) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (quantidade != null && quantidade <= 12) {
          this.displayBasic = true
        }
      }, 1000)
    },
    carregarRubricas() {
      const atividades = [
        'EMPRESTIMO',
        'PLANO DE SAUDE',
        'ASSOCIACAO',
        'PLANO FUNERARIO',
        'SEGURO',
        'SINDICATO',
      ]
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      if (this.tipo !== null && this.tipo === 'RENEGOCIACAO') {
        this.rubricaService
          .getListaRubricasAtivasPorAtividadesDesconsiderarRubrica(
            atividades,
            this.matricula.id,
            consignataria.id,
          )
          .then(
            (res) => {
              this.rubricas = res
            },
            (err) => {
              if (err) {
                this.exibeToast('error', err.response.data.message)
              }
            },
          )
      } else {
        this.rubricaService
          .getListaRubricasAtivasPorAtividades(
            atividades,
            this.matricula.id,
            consignataria.id,
          )
          .then(
            (res) => {
              this.rubricas = res
            },
            (err) => {
              if (err) {
                this.exibeToast('error', err.response.data.message)
              }
            },
          )
      }
    },

    validate() {
      if (this.tipo !== null && this.tipo === 'COMPRA_DE_DIVIDA') {
        if (
          this.proposta.valorParcela >
          this.totalSomaValoresConsignacoesSelecionadas
        ) {
          this.valorParcelaMenorIgualTotalConsignacoesSelecionadas = true
        } else {
          this.valorParcelaMenorIgualTotalConsignacoesSelecionadas = false
        }
      }

      this.submitted = true
      this.v$.proposta.$touch()

      if (
        this.v$.proposta.$invalid ||
        this.valorParcelaMenorIgualTotalConsignacoesSelecionadas
      ) {
        return
      } else {
        this.exibirModalSenhaServidor = true
      }
    },

    validate2() {
      this.v$.proposta.$touch()

      if (this.v$.proposta.$invalid) {
        return
      }
    },

    async salvar() {
      this.validate2()
      this.atribuirDados()
      if (
        this.tipo !== null &&
        (this.tipo === 'COMPRA_DE_DIVIDA' || this.tipo === 'RENEGOCIACAO')
      ) {
        let res = await Promise.resolve(this.metodoSalvar(this.proposta)).catch(
          (err) => {
            this.exibeToast('error', err.response.data.message)
            this.tratarCarencia()
            this.saving = false
          },
        )
        this.limparCampos()
        return typeof res === 'undefined' ? 'error' : res
      } else {
        let res = await Promise.resolve(
          this.propostaService.savePropostaConsignacao(this.proposta),
        ).catch((err) => {
          this.exibeToast('error', err.response.data.message)
          this.tratarCarencia()
          this.saving = false
        })
        return typeof res === 'undefined' ? 'error' : res.data
      }
    },

    aprovarProposta() {
      this.salvar().then((res) => {
        if (res !== 'error') {
          this.propostaService.aprovarProposta(res).then(
            (resp) => {
              this.exibeToast('success', 'Averbação realizada com sucesso!')
              this.limparCampos()
              this.irParaConsignacaoDetalhe(resp.data)
            },
            (err) => {
              this.exibeToast('error', err.response.data.message)
              this.saving = false
            },
          )
        }
      })
    },

    finalizarSemAprovarProposta() {
      this.proposta.on_line = true
      this.salvar().then((res) => {
        if (res !== 'error') {
          this.exibeToast(
            'success',
            'Proposta criada com pendência de aprovação por parte do Servidor!',
          )
          this.limparCampos()
        }
      })
    },

    atribuirDados() {
      this.proposta.valorTotal =
        this.proposta.valorParcela * this.proposta.prazoTotal
      this.proposta.matricula = this.matricula
      this.proposta.tipo = this.tipo
      this.salvarMesEAnoReferencia()
    },

    limparCampos() {
      this.submitted = false
      this.saving = false
      this.v$.$reset()
      this.proposta = new Proposta()
      this.datasCarencia = [
        {
          smesCarencia: '',
          mesReferencia: 0,
          anoReferencia: 0,
        },
      ]
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },

    adicionarAverbacao() {
      this.novaAverbacao.rubrica = String(this.proposta.rubrica.rubrica).concat(
        ' | ',
        this.proposta.rubrica.nome,
        ' | ',
        this.proposta.rubrica.atividade?.nome,
      )
      this.novaAverbacao.numeroContrato = this.proposta.contrato
      this.novaAverbacao.valorParcela = parseFloat(
        this.proposta.valorParcela,
      ).toFixed(2)
      this.novaAverbacao.prazoTotal = this.proposta.prazoTotal
      this.novaAverbacao.carencia = this.proposta.carencia.smesCarencia
      this.novaAverbacao.mesReferencia = this.proposta.carencia.mesReferencia
      this.novaAverbacao.anoReferencia = this.proposta.carencia.anoReferencia
    },

    tratarCarencia() {
      const date = new Date()
      let currentDay = date.getDate()
      let currentMonth = date.getMonth() + 1
      let currentYear = date.getFullYear()
      let novasDatasCarencia = []
      let diaCorte = this.proposta.rubrica.entidade.diaCorte

      let interacoes = 7
      if (currentDay > diaCorte) {
        if (currentMonth == 12) {
          currentMonth = 1
          currentYear++
        } else {
          currentMonth++
        }
      }
      for (let count = 0; count < interacoes; count++) {
        novasDatasCarencia.push({
          smesCarencia: String(currentMonth).concat('/', currentYear),
          mesReferencia: currentMonth,
          anoReferencia: currentYear,
          carencia: count,
        })
        if (currentMonth == 12) {
          currentMonth = 1
          currentYear++
        } else {
          currentMonth++
        }
      }
      this.datasCarencia = novasDatasCarencia
      this.proposta.carencia = this.datasCarencia[0]
    },

    salvarMesEAnoReferencia() {
      this.proposta.mesReferencia = this.proposta.carencia.mesReferencia
      this.proposta.anoReferencia = this.proposta.carencia.anoReferencia
      this.proposta.carencia = this.proposta.carencia.carencia
    },

    irParaConsignacaoDetalhe(data) {
      this.$router.push({
        name: 'consignacao-detalhe',
        params: { idconsig: data.id },
      })
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
